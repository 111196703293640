import React from 'react';
import { Helmet } from 'react-helmet';

import { Box, Heading, Text } from '@chakra-ui/core';

export default function publishlist() {
  return (
    <Box mt="100px" mb="100px">
      <Helmet>
        <title> مهرجان الثقافة العربية في ميلانو</title>
      </Helmet>
      <Heading
        fontFamily="diodrum-bold !important"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
      >
        مهرجان الثقافة العربية في ميلانو
        {/* <Divider
          mt="4"
          opacity="1"
          border="3px solid black"
          pl="20%"
          pr="20%"
          //   m="4"
          borderColor="black"
          w="20%"
        ></Divider> */}
      </Heading>
      <Box
        textAlign="right"
        p="30px"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Text fontSize="2xl" m="4">
          منشورات المتوسط شريكة منظمة لــ "المهرجان الدولي للغة العربية
          وثقافاتها" بالتعاون مع الجامعة الكاثوليكية في ميلانو (إحدى أكثر
          الجامعات المرموقة إيطالياً وعالمياً)، والذي تُنظم فيه المتوسط معرضاً
          للكتاب العربي وللكتاب الإيطالي عن العالم العربي، وعدة أنشطة أدبية
          وفنية يشارك فيها كتّاب وفنانون عرب وإيطاليون في مدينة ميلانو.
        </Text>
        <Text fontSize="2xl" m="4">
          خلال أيام المهرجان ولأربع طبعات متتالية، تقامُ فعاليات معرض الكتاب
          العربي والكتاب الإيطالي عن العالم العربي؛ بمشاركة عدد من دور النشر
          العربية، حيث يشرف مدير منشورات المتوسط ومدير اللجنة المنظمة، على حضور
          عدد من دور النشر على غرار: الجديد، الساقي، التنوير، المدى، المؤسسة
          العربية للدراسات والنشر، الفارابي، ممدوح عدوان، العين، والمتوسط، وذلك
          سعيا لمنح الكتاب العربي فضاء للتفاعل والحوار وإبراز إبداعات الكُتّاب
          العرب في شتى المجالات الفكرية والأدبية.
        </Text>
        <Text fontSize="2xl" m="4">
          كما يعرف المعرض مشاركة مهمّة لعدد من المكتبات الأوروبية، وأجنحة تضمّ
          كتباً تهتمّ بكل ما يُكتب ويُترجم عن العالم العربي في إيطاليا. كما يشهد
          برنامج المهرجان تنظيم أمسيات شعرية، وحفلات موسيقية محترفة، وعروض
          سينمائية، تعكس وجوها متنوعة للمشهد الشعري والسينمائي والفني العربي،
          مهما تباعدت الجغرافيات أو اختلفت الجنسيات.
        </Text>
        <Text fontSize="2xl" m="4">
          المهرجان فضاء ثقافي مفتوح على الأدب والموسيقى والسينما، والندوات
          الفكرية للمؤتمر الذي سعى في طبعته لسنة 2019 إلى الإجابة عن عدد من
          الأسئلة التي تناولت قضايا المرأة في مجالات اللغة والإبداع منها: متى
          اقتحمت المرأة العربية الفضاء الثقافي العام للمجتمع الحديث في مجالات
          التعليم والصحافة والكتابة الأدبية والمسرح وفي السينما وفي الغناء
          والموسيقى؟ ما هو السياق الثقافي الذي حكم هذا الحضور؟ وما هي آثاره على
          اللغة العربية المعاصرة؟ وهل أدى إلى تغيير في صورة المرأة ورمزيتها في
          المخيال الجمعي وفي الأعمال الأدبية؟
        </Text>
        <Text fontSize="2xl" m="4">
           كما ساءَل المهرجان أثر كل ذلك على الموقف من حقوق المرأة، وهل أدى إلى
          تغيير في لغة نصوص القوانين والدساتير؟ ما هي العقبات اللغوية والثقافية
          التي تحول دون اندماج المرأة الكامل في المجتمع؟ هل يمكننا الحديث عن أدب
          عربي نسوي؟ ما الذي يميزه لغوياً وجمالياً عن غيره من الكتابات الأدبية؟
        </Text>
        <Text fontSize="2xl" m="4">
          أسئلة متنوعة شاركَ في الإجابة عنها ومناقشتها مجموعة من الأساتذة
          والمختصين والمهتمين بقضايا المرأة في مجالات اللغة والإبداع، من مختلف
          البلدان العربية وبمشاركة أساتذة وباحثين من إيطاليا.
        </Text>
        <Text fontWeight="bold" fontSize="2xl" m="4">
          مهرجان الثقافة العربية في ميلانو هو مهرجان سنوي تُنظمه الجامعة
          الكاثوليكية في ميلانو ربيع كلَّ عام، وتسعى من خلاله المتوسط الشريك
          الأساسي في التنظيم، إلى خلق فضاءٍ أدبي وفني مفتوح بين المبدعين العرب
          والأجانب وبين طلبة الجامعة والتجارب الإبداعية العربية في شتّى
          المجالات.
        </Text>
      </Box>
    </Box>
  );
}
