import React from 'react';
import { Helmet } from 'react-helmet';

import { Box, Heading, Text, List, ListItem, Image } from '@chakra-ui/core';
import translation from '../../images/conf-min.jpg';

export default function publishlist() {
  return (
    <Box mt="100px" mb="100px">
      <Helmet>
        <title> ملتقى الترجمة والأدب العالمي نحو مأسسة الفعل الثقافي</title>
      </Helmet>
      <Heading
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
        fontFamily="diodrum-bold !important"
      >
        ملتقى الترجمة والأدب العالمي نحو مأسسة الفعل الثقافي
        {/* <Divider
          mt="4"
          opacity="1"
          border="3px solid black"
          pl="20%"
          pr="20%"
          //   m="4"
          borderColor="black"
          w="20%"
        ></Divider> */}
      </Heading>
      <Box d="flex" justifyContent="center" m="4">
        <Image
          loading="lazy"
          w={['90%', '90%', '60%', '60%']}
          src={translation}
        ></Image>
      </Box>
      <Box
        textAlign="right"
        p="30px"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Text fontSize="2xl" m="4">
          أطلقت منشورات المتوسط فكرة تنظيم ملتقى للترجمة عام 2016، حيث عقدت سنته
          الأولى في مدينة ميلانو الإيطالية، وكان هدفها هو تسليط الضوء على
          الترجمة بوصفها نشاطاً ثقافياً ومؤسساً وفاعلاً في أي عملية تبادل ثقافي
          وبين ثقافي.
        </Text>
        <Text fontSize="2xl" m="4">
          عليه فإن ملتقى الترجمة يريد أن يكون منصَّة مفتوحة للكتّاب والمترجمين
          العرب والأجانب أصحاب المشاريع النوعيّة والجادّة التي تهتمّ بقضايا
          الترجمة من العربية إلى اللغات الأجنبية وإليها. وكيف يمكن لهذا الفعل
          الإبداعي أن يتجاوز الأطر الفنية والأدبية إلى ما هو إنساني في بناء
          الجسور بين الشعوب والحضارات وتجاوز الحواجز السياسية والجغرافية بين
          بلدان العالم.
        </Text>
        <Text fontSize="2xl" m="4">
          ملتقى الترجمة هو كذلك لقاءات مفتوحة ما بين مفكرين وأدباء ومترجمين عرب
          ومترجمين أجانب من اللغة العربية وناشرين أجانب، تتوجَّه إلى جمهور
          المهتمّين من المبدعين والطلبة والباحثين، وتُخصَّص للحديث عن الترجمة
          وآفاقها، وعن أهمية ترجمة الأدب العربي ونشره وآليات تنشيطه في اللغات
          الأخرى.
        </Text>
        <Text fontSize="2xl" m="4">
          ضيوف الملتقى:
        </Text>
        <List m="4" fontSize="2xl" styleType="decimal">
          <ListItem>
            .كتاب ومفكرون أجانب ومترجموهم وناشروهم إلى اللغة العربية
          </ListItem>
          <ListItem>
            .مترجمون من اللغة العربية إلى اللغات الأخرى، ومستعربون أيضاً
          </ListItem>
          <ListItem>.مترجمون من اللغات الأخرى إلى اللغة العربية</ListItem>
          <ListItem>
            ناشرون أجانب مهتمون بترجمة الأدب العربي ونشره في لغاتهم، وأصحاب
            مشاريع ثقافية متنوعة.
          </ListItem>
        </List>
        <Text fontSize="2xl" m="4">
          بما أن منشورات المتوسط ماهي إلا مؤسسة خاصة ذات إمكانيات محدودة فقد
          عملت دائماً وآمنت بمبدأ سعت إلى تكريسه وهو مبدأ التكامل الثقافي بين
          المؤسسات الثقافية الخاصة والمؤسسات الثقافية والمجتمعية الرسمية وشبه
          الرسمية، لا سيما وزارات الثقافة والمعاهد الثقافية والجامعات، عبر دول
          حوض البحر الأبيض المتوسط لا سيما العربية منها، والعمل على توسيع مجال
          التبادل الثقافي والإنساني إلى أبعد من ذلك، من خلال استضافة أسماء وازنة
          لها مشاريعها ذات البعد الحضاري والتاريخ المشترك، على غرار المفكر
          والأستاذ الجامعي حميد دبّاشي، الكاتب والصحفي الإيطالي جوزبِّه
          كاتوتسيلا، الباحثة والأستاذة الجامعية الإسبانية لوث غوميس غارسيا،
          جوناثان رايت الناقد البريطاني ومترجم الأدب العربي إلى الإنكليزية،
          المترجم والباحث التونسي عز الدين عناية، والكاتبة والمترجمة العراقية
          دُنى غالي.
        </Text>
        <Text fontSize="2xl" m="4">
          تتطلَّع منشورات المتوسط إلى تنظيمِ ملتقى الترجمة عبر عدَّة مدن عربية
          بهدف مأسسة الفعل الثقافي الخاص بالترجمة، وفتحِ نقاشٍ موسَّع ينقلُ من
          وإلى اللغة العربية تجارب إنسان البحر الأبيض المتوسط وقضاياه المعاصرة،
          ضمنَ رؤيةٍ تحتفي بالأبعاد المختلفة والمتنوّعة للثقافة المتوسطيّة.
          وعليه تواصل منشورات المتوسط البحث عن شركاء لها يساهمون بنقل هذا
          المشروع إلى بلدانهم. خاصة أن تجربتنا حققت نجاحاً راسخاً بنتائج واضحة.{' '}
        </Text>
        <Heading m="4" size="lg">
          عن تجربتنا السابقة في فلسطين:
        </Heading>
        <Text fontSize="2xl" m="4">
          لسنتيْنِ متتاليتيْن 2017 و2018، احتضنت فلسطين، فعاليات: أيام الترجمة
          والأدب العالمي، تحت شعار: "فلسطين: الحضارة وتواصل المعرفة"، بالتعاون
          بين منشورات المتوسط ووزارة الثقافة الفلسطينية. الأيام وفي دورتيها
          الأولى والثانية جاءت بهدف مأسسة الفعل الثقافي واستمرار النضال الأدبي
          والفنّي والإنساني للشعب الفلسطيني من أجل الحرية. حيثُ حلَّ بمدينة رام
          الله عددٌ من الأدباء العالميين رفقة مترجميهم العرب إلى العربية، إضافة
          إلى مترجمين لهم تجاربهم الخاصة في الترجمة عموماً وفي ترجمة الأدب
          العربي إلى لغات أخرى خصوصاً، ناهيك عن اهتماماتهم بأزمات الإنسان
          المعاصر وقضايا الوطن والحرية والدين وحوار الحضارات.
        </Text>
      </Box>
    </Box>
  );
}
