import React from 'react';
import { Helmet } from 'react-helmet';

import { Box, Heading, Text, Image } from '@chakra-ui/core';
import divine from '../../images/comedy-min.jpg';

export default function publishlist() {
  return (
    <Box mt="100px" mb="100px">
      <Helmet>
        <title> الكوميديا الإلهية</title>
      </Helmet>
      <Heading
        fontFamily="diodrum-bold !important"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
      >
        الكوميديا الإلهية
      </Heading>
      <Text
        fontFamily="diodrum-med !important"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        fontSize="2xl"
      >
        إعادة إنتاج "الكوميديا الإلهية" عربياً
        {/* <Divider
          mt="4"
          opacity="1"
          border="3px solid black"
          pl="20%"
          pr="20%"
          //   m="4"
          borderColor="black"
          w="20%"
        ></Divider> */}
      </Text>

      <Box d="flex" justifyContent="center" m="4">
        <Image
          loading="lazy"
          w={['90%', '90%', '60%', '60%']}
          src={divine}
        ></Image>
      </Box>
      <Box
        textAlign="right"
        p="30px"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Text fontSize="2xl" m="4">
          بدأت منشورات المتوسط العمل على إعادة إنتاج ترجمة حسن عثمان لكتاب
          "الكوميديا الإلهية" لـ دانتي أليغييري، حيث كلَّفت لجنة علمية من كبار
          الأكاديميين والمترجمين المتخصصين في ترجمة ونقل التراث بين اللغتين
          الإيطالية والعربية، ببدء العمل على المشروع.
        </Text>
        <Text fontSize="2xl" m="4">
          تأتي هذه الخطوة لإعادة إحياء رائعة دانتي أليغييري عربياً، والتي شكَّلت
          فجر عصر النهضة الأوروبي، ولم تفقد ألقها وتأثيرها المستمرّ في أجيالٍ
          متعاقبة من الأدباء والمفكّرين والفنّانين الذين أغنوا مرجعياتهم الفنية
          واللغوية بقراءة هذا العمل الفريد، في لغاتٍ لا حصر لها عبر العالم،
          وبترجماتٍ عدَّة في اللُّغة الواحدة.
        </Text>
        <Text fontSize="2xl" m="4">
          لعلَّ الاهتمام العربي الذي بدأ منذ ثمانينيات القرن التاسع عشر بـ
          الكوميديا الإلهية، أفرزَ فيما بعد أكثر من ترجمة واحدة للعمل، أضافت
          كلها إضاءات جوهرية وهامة على العمل الأدبي الأهم في تاريخ الأدب
          الإيطالي إن لم يكن العالمي. لكن من بين هذه الترجمات كلها، تظل ترجمة
          حسن عثمان، المؤرخ وأستاذ التاريخ الإسلامي والأوروبي؛ هي الأكثر إغراءً
          والألمع وذلك لأسباب كثيرة. تفرَّغَ حسن عثمان لدراسة دانتي والكوميديا
          الإلهية خاصة لمدة 10 سنوات من سنة 1941 وحتى سنة 1951، حيث شرع بترجمة
          الكوميديا الإلهية والتي استغرقته ثماني سنوات أخرى. واصل خلالها السفر
          على نفقته الخاصة وتارة كانت "اليونيسكو" تغطي له بعض تلك النفقات، زار
          مصادر العلوم التي نهل منها دانتي، وتتبع آثار الشاعر وأسفاره، وعايش
          الأماكن التي عاش فيها، خاض في الفنون التشكيلية وفي الموسيقى لدراسة
          ذائقة دانتي الفنية والموسيقية. وإذا كان المختصون قد اختلفوا على المدة
          التي استغرقها دانتي في كتابة الكوميديا الإلهية، فمنهم من قال أربع عشرة
          سنة ومنهم من ذهب إلى إثنين وعشرين سنة، فنحن أكيدون بأن مترجمها إلى
          العربية استغرقته ثماني عشرة سنة. وهذا ما لم يحدث في لغات أخرى حسب
          علمنا.
        </Text>
        <Text fontSize="2xl" m="4">
          فحسن عثمان الباحث قبل أن يكون المترجم، هو من رواد منهج البحث التاريخي
          وكتابه المعنون بـ "منهج البحث التاريخي" يعد مرجعاً أساسياً في اختصاصه
          حتى أيامنا هذه، وقد تمكن بصدق من تطبيق هذا المنهج في اشتغاله على ترجمة
          الكتاب الذي يأتي تصنيفه الثاني بعد الكتاب المقدس غربياً وهو الكوميديا
          الإلهية. ولهذا السبب تجاوزت ترجمته للكوميديا الإلهية الترجمة بحد
          ذاتها، وجاءت النتيجة أننا أمام دراسة تاريخية شاملة لفترة حاسمة في
          تاريخ الفكر الإنساني وهي لحظة بزوغ عصر النهضة. عليه، لا يأتي هذا
          المشروع احتفاء بدانتي وبالكوميديا الإلهية فحسب، بل أيضاً، بهذا المترجم
          الاستثناء ليس عربياً فقط وإنما عالمياً، ولعلنا ننجح بتحقيق آماله التي
          زرعها في نهاية ترجمته للكوميديا الإلهية حين قال: «وبعدُ، فإني أؤمل أن
          يأتي من بعد مَنْ يدرس، ويرتحل، ويصبر، ويستوعب، ويتأمل، ويستلهم، ويمسك
          باليراع لكي يعبّر عن دانتي في صورة أوفى وأجمل!»
        </Text>
        <Text fontSize="2xl" m="4">
          بدأ المشاركون العمل على الترجمة، حيث شُكلت لجنة علمية من مجموعة من
          ألمع المترجمين المتخصصين في ترجمة ونقل التراث بين اللغتين الإيطالية
          والعربية تساندها مجموعة من الأكاديميين المتخصصين في تاريخ القرون
          الوسطى وآدابه ولاهوته، وفي لغة دانتي والبلاغة الجديدة التي أسس لها،
          والآثار التي تركتها إلى اليوم على الآداب والفنون الغربية، وأخيراً في
          الأدب المقارن، حيث تتتبع آثار الثقافات الأخرى في الكوميديا الإلهية
          وآثارها العابرة للغات على الثقافات الأخرى. وخرجت هذه اللجنة بتصور
          مشترك حول ما ينبغي تقديمه من إضافة وتحديث على النص، مستندين على
          الدراسات الحديثة التي صدرت وتناولت الكوميديا الإلهية في السنوات
          الأخيرة. باختصار هم سوف يدرسون، ويرتحلون، ويصبرون، ويستوعبون،
          ويتأمَّلون، ويستلهمون، ويمسكون باليراع لكي يعبّروا عن ترجمة حسن عثمان
          في صورة أوفى وأجمل والتي بالتالي ستعكس صورة أجمل وأوفى لدانتي.
        </Text>
        <Text fontSize="2xl" m="4">
          أطلقت إذن، منشورات المتوسط مشروعها هذا ونحن على بعد شهور قليلة من حلول
          الذكرى الـ700 لوفاة دانتي أليغييري، وتأمل الدار أن يرى مشروعها النور
          مع حلول ذكرى وفاة حسن عثمان الخمسين والتي ستحل في العام 2023.
        </Text>
        <Text fontSize="2xl" m="4">
          وكانت المتوسّط ومنذ تأسيسِها، قد عملت على نقلِ الأدب والفكر الإيطالي
          إلى اللغة العربية، وقدمت الدار أعمالاً كثيرة لأسماء كبيرة تتُرجم لأول
          مرَّة إلى العربية، وأيضاً اهتمت بشكلٍ كبير بإعادة مراجعة وترميم وتحديث
          الترجمات القيِّمة القديمة، بداية من رواية "الفهد" للكاتب جوزيبِّه
          تومّازي دي لامبيدوزا ورواية فونتمارا لـ إينياتسيو سيلونه. وهو ما جعلها
          داراً مختصّة في الأدب الإيطالي بقدر اهتمامها بالعالم العربي.
        </Text>
        <Heading size="lg" m="4">
          الأكاديميون والمترجمون المشاركون حسب الترتيب الأبجدي:
        </Heading>
        <Text fontSize="2xl" m="4">
          <b>أمارجي: </b>الاسم الأدبيُّ للشَّاعر والمترجم السُّوري رامي يونس.
          وُلدَ بمدينة اللاذقية سنة 1980، صدر له شعراً أربع مجموعاتٍ شعرية، هي:
          «ن»، 2008. «بِيرودجا: النَّص- الجسد»، 2009. «مِلاحاتٌ إيروسيَّة»،
          2011. «وردةُ الحيوان»، 2014. «فيلولوجيا الأزهار»، 2020. حازَ شهادتَي
          ماجستير من جامعتَي كاتانيا، وبيرودْجا في إيطاليا، وتخصَّص في ترجمة
          الأدب الإيطالي، حيثُ نقلَ إلى العربية العديد من الأعمال لكبار الكتّاب
          والشعراء، نذكر من بين ترجماته الروائية: «البحر المحيط»، لـ ألِسَّاندرو
          باريكُّو، 2017. «واحدٌ ولا أحد ومِائة ألف، لـ لويجي بيراندللو، 2017.
          «زهرة القيامة (عجائب الألفيَّة الثَّالثة)» لـ إميليو سالغاري، 2018.
          صدرت جميعها عن منشورات المتوسط.
        </Text>
        <Text fontSize="2xl" m="4">
          تُوِّجَ أمارجي بعدَّة جوائز أدبية، منها: الجائزة الأولى في الشِّعر
          خلال مهرجان آذار الثَّامن عشر للأدباء الشَّباب 2001. الجائزة الأولى في
          الشِّعر، جائزة M.A.R.I.C الدَّوليَّة للشِّعر والنَّثر التي تنظِّمها
          «الحركة الفنِّيَّة لاستعادة الهويَّات الثَّقافيَّة» بمدينة سالِرْنو في
          إيطاليا 2018.
        </Text>
        <Text fontSize="2xl" m="4">
          <b>أماني فوزي حبشي: </b>من مواليد القاهرة، 1968. حصلت على ماجيستير في
          الترجمة ودكتوراه في الأدب الإيطالي من كلية الألسن جامعة عين شمس. حصلت
          على الجائزة الوطنية الإيطالية للترجمة عام 2003 لإسهاماتها في نشر
          الثقافة الإيطالية، وعلى نجمة التضامن الإيطالي، رتبة فارس عام 2004.
          وشاركت بالعديد من المقالات والأبحاث الخاصة بالثقافة الإيطالية
          والترجمة، والتي نُشرت في مختلف الصحف والمجلات المصرية. أسهمت في تأسيس
          صفحة «المقهى الثقافي الإيطالي» عام 2017 والتي تعمل كببليوغرافيا
          للأعمال المُترجمة من اللغة الإيطالية إلى اللغة العربية. تعاونت مع
          العديد من الناشرين في مصر، والسعودية، وأبو ظبي، والكويت، ولبنان.
        </Text>
        <Text fontSize="2xl" m="4">
          من أهم ترجماتها: «بندول فوكو» لـ«أومبرتو إيكو»، «ثلاثية أسلافنا:
          الفسكونت المشطور، البارون ساكن الأشجار وفارس بلا وجود» لـ«إيتالو
          كالفينو»، «شجاعة طائر أبو الحن» لـ«ماوريتزيو ماجّاني»، «بلا دماء»
          لـ«أليساندرو باريكو»، و«اذهب حيث يقودك قلبك» و«صوت منفرد» لـ«سوزانا
          تامارو»، «أربطة» لـ«دومينيكو ستارنونه»، «أصوات المساء» ل«نتاليا
          جينزبورج»، «الجبال الثمانية» لـ«باولو كونيّتي»، «كوزيما» لـ«غراتسيا
          ديليدا»، وغيرها.
        </Text>
        <Text fontSize="2xl" m="4">
          <b>إيدّا زولّو غراندي: </b>أستاذة اللغة والأدب العربي في جامعة Ca’
          Foscari (البندقية) قسم دراسات آسيا وإفريقيا البحر متوسطية (DSAAM)،
          حاصلة على دكتوراه في دراسات الشرق الأدنى والمغرب من جامعة
          L’Orientaleفي نابولي.
        </Text>
        <Text fontSize="2xl" m="4">
          يرتكز نشاطها العلمي على الدراسات القرآنية، حيث قامت بتأليف أبحاث عن
          لغة القرآن وتأثيرها على الإنتاج الأدبي اللاحق، وفي هذا السياق نذكر
          أبحاثها عن مفهوم الشر في القرآن (2002) وشخصية سيدتنا مريم (1996-1997)
          وقابيل والفرعون وسيدنا يونس (2006) وعن الحرية الدينية (2008) وعن صورة
          المرأة في القرآن (2009) وعن الإسراء والمعراج (2010). كما قامت بترجمة
          القرآن بتعليقات توضيحية له (2010) وألفت بعض الدراسات عن الملائكة
          وأشرفت على إصدار «قاموس القرآن». (2007).
        </Text>
        <Text fontSize="2xl" m="4">
          وفي السنوات الأخيرة أصدرت أبحاثاً عن بعض الجوانب الأخلاقية لدين
          الإسلام، ومنها ضرورة العمل والشُكر والتوبة والحِلم. وتحليلاتها تنطلق
          من مصادر تفسير القرآن الرئيسية وأيضًا من الأدب القديم (مثل ابن أبي
          الدنيا) والأدب المعاصر. وألفت أيضاً بعض الأبحاث المقارنة بين الأديان
          السماوية في النصوص العربية من القرون الوسطى، ودرست المراسلات بين ابن
          المنجم وقستا ابن لوقا وبعض الأعمال عن نشر العلوم، على سبيل المثال
          الأعمال عن تأثير الدين المسيحي على تكوين الفلسفة العربية. كما قامت
          بتأليف أبحاث عن التغيرات الثقافية والاجتماعية الناتجة عن حضور الشعوب
          العربية في إيطاليا وأصدرت ترجمة الدستور الإيطالي إلى اللغة العربية
          (2010).
        </Text>
        <Text fontSize="2xl" m="4">
          الباحثة عضوة في الكثير من اللجان العلمية والمراكز الدراسية مثل لجنة
          المجلة العلمية السنوية لجامعة Ca’ Foscari، ومركز الدراسات الإنسانية
          بجامعة Ca’ Foscari، ومعهد الشرق «ألفونسو نالّينو» (IPOCAN)، واللجنة
          العلمية الدولية لماجستير عن دراسات الإسلام في أوروبا بجامعة بادوفا،
          ومركز الأبحاث «الأديان والحقوق والاقتصاد في الفضاء البحر متوسطي
          (REDESM) » بجامعة إنسوبريا. كما هي أضًا مستشارة دار نشر Einaudi
          للأعمال العربية والإسلامية.
        </Text>
        <Text fontSize="2xl" m="4">
          <b>عز الدين عناية: </b>أستاذ جامعي تونسي ومترجم عن اللغة الإيطالية.
          مواليد 1966 في مدينة سوسة، يقيم في روما ويُدرّس في جامعتها، متخصّص في
          دراسات الأديان. حاصل على الأستاذية والدكتوراه من جامعة الزيتونة في
          تونس في اختصاص الأديان والمذاهب. صدرت له مجموعة من الأبحاث منها:
          «الدين في الغرب» 2017، «الأديان الإبراهيمية» 2013، «نحن والمسيحية في
          العالم العربي وفي العالم» 2010، «الاستهواد العربي» 2006؛ فضلا عن عدد
          هام من الترجمات منها: «المنمنمات الإسلامية» لماريا فيتوريا فونتانا
          2015، «علم الاجتماع الديني» لإنزو باتشي 2011، «علم الأديان» لميشال
          مسلان 2009.
        </Text>
        <Text fontSize="2xl" m="4">
          له عدَّة مقالات ودراسات في الدوريات والصحف العربية تتناول الظواهر
          الدينية، كما صدرت تحت إشرافه مجموعة من الترجمات عن الإيطالية في
          الرواية والفكر والسياسة.
        </Text>
        <Text fontSize="2xl" m="4">
          <b>گاصد محمد: </b>كاتب ومترجم عراقي (بابل 1981)، أستاذ اللغة والأدب
          العربي في جامعة بولونيا وجامعة ماﭼيراتا. نال شهادة البكلوريوس في اللغة
          الايطالية في بغداد عام 2006، ثم حصل على ماستر من سنة واحدة من معهد
          الدراسات الإنسانيّة في فلورنسا، عام 2008، حول الثقافة الأوربيّة
          وجذورها التاريخيّة. أكمل دراساته العليا في ايطاليا، فنال الماجستير عام
          2011 عن بحث مقارن ما بين الديكاميرون وألف ليلة وليلة، ثمّ أتمّ
          الدكتوراه عام 2015 عن بحث مقارن تناول الجذور العربيّة في الديكاميرون.
        </Text>
        <Text fontSize="2xl" m="4">
          يكتب بالعربيّة والايطاليّة، وقد صدرتْ له مجموعة شعريّة باللغة
          الايطاليّة تحت عنوان «الحياة ليستْ مقبرة جماعيّة»، عن دار النشر
          "لاركولايو". وصدرت له نصوص وقصص في عدّة مجلات ودوريات ايطاليّة.
          تُرجمتْ بعض نصوصه إلى اللغة الرومانيّة.
        </Text>
        <Text fontSize="2xl" m="4">
          ترجم إلى العربيّة مجموعة روايات: «القرصان الأسود» أميليو سلغاري،
          «الصيف الجميل، الشيطان فوق التلال» تشيزره ﭘـاڤيزه، «شيخوخة» ايتالو
          زڤيڤو، «مدينة» أليسّاندرو باريكّو.
        </Text>
        <Text fontSize="2xl" m="4">
          وترجم إلى الايطاليّة: المجموعة الشعريّة «التعليمات بالداخل» أشرف
          فيّاض، رواية «مرسى فاطمة» حجي جابر، المجموعة الشعريّة «قارب إلى
          ليسبوس» نوري الجراح.
        </Text>
        <Text fontSize="2xl" m="4">
          <b> نجلاء والي: </b>أكاديمية وباحثة ومترجمة مصرية، حاصلة على دكتوراه
          الآداب في اللغة الإيطالية بدرجة مرتبة الشرف الأولى من كلية الألسن –
          جامعة عين شمس ودرجة الماجيستر في الأدب العربي والدراسات الإسلامية من
          جامعة الدراسات الشرقية في نابولي وحالياً تعمل مدرِّس للغة العربية
          وتقنيات الترجمة من اللغة العربية إلى الإيطالية بجامعة تورينو.
        </Text>
        <Text fontSize="2xl" m="4">
          صدر لها العديد من المقالات الأكاديمية والعديد من الترجمات من الأدب
          الإيطالي إلي اللغة العربية ومن الأدب العربي إلي الإيطالية منها:
          «حكايات كالفينو» لإيتالو كالفينو (المجموعة الكاملة للحكايات الشعبية
          الإيطالية وقد صدرت في أربع مجلدات)، 2007. «ابتسامة البحار المجهول»
          لفينشينسو كونصلو، 2009. «حياة ماريانا أوكريا» لداتشيا ماراييني، 2012.
          «قاطفة اللوز» لسيمونتا أنييللو هورنوبي، 2015. ترجمة المجموعة الشعرية
          «بائعة الفل» للشاعر عماد عبد المحسن إلى اللغة الإيطالية، 2017. «زهور
          نهر تيزا» لدانتي مارياناتشي، 2017.
        </Text>
        <Text fontSize="2xl" m="4">
          نالت جائزة الإنجاز في ترجمة الأدب الإيطالي إلى اللغة العربية -الجائزة
          الوطنية الإيطالية للترجمة من وزارة التراث والأنشطة الثقافية والسياحية
          الإيطالية، 2017. وجائزة البحر المتوسط للثقافة – الدورة السادسة، 2016.
        </Text>
        <Text fontSize="2xl" m="4">
          <b>ماريّانا ماسّا: </b>مترجمة إيطالية منذ عام 2008، حاصلة عـلى ماجستير
          في كلية العلوم العربية الإسلامية من جامعة L’Orientale في نابولي بتخصص
          في الأدب العربي المعاصر، كما حصلت على شهادتين في الترجمة الصحفية
          والسمعبصرية من الجامعة الأمريكية في القاهرة.
        </Text>
        <Text fontSize="2xl" m="4">
          صدرت لها ترجمتان من العربية إلى الإيطالية، «أنغام البيانو»، لأبو
          المعاطي أبو شارب (صدرت عن دار نشر Cosmo Iannone)، و«العار على الضفتين»
          لعزت القمحاوي (صدرت عن دار نشر Edizioni Ensemble). كما تعاونت ماريانا
          مع إسلام فوزي -قسم اللغة الإيطالية في كلية الألسن بـ عين شمس، في
          الترجمة العربية للرواية التاريخية الإيطالية «حذارِ من جوعي»، لوشيانا
          كاستيلينا وميلينا أغوس. ولها ترجمة رواية «بيير ولوس» من الفرنسية إلى
          العربية. ترجمت مجموعة من الكتب للأطفال من العربية إلى الإيطالية لهيئة
          الشارقة للكتاب.
        </Text>
        <Text fontSize="2xl" m="4">
          تعمل بشكل خاص في مجال الترجمة السمعبصرية من العربية إلى الإنجليزية
          وإلى الإيطالية وترجمت أكثر من أربعين فيلماً بين أفلام روائية طويلة
          وقصيرة وأفلام وثائقية ودعائية. وتعمل أيضاً مترجمة فورية من العربية إلى
          الإيطالية لجهات عدَّة.
        </Text>
        <Text fontSize="2xl" m="4">
          <b>وائل فاروق: </b>ناقد وأكاديمي وشاعر ومترجم مصري، حاصل على درجة
          الأستاذية في لغات وثقافات الشرق الأدنى من وزارة التعليم العالي والبحث
          العلمي في إيطاليا، يعمل حاليا أستاذاً للدراسات العربية والإسلامية في
          كلية العلوم اللغوية والآداب الأجنبية وكلية العلوم السياسية والاجتماعية
          بالجامعة الكاثوليكية للقلب المقدس في ميلانو. عمل أستاذاً زائراً في
          معهد ستراوس للدراسات المتقدمة في القانون والعدالة، جامعة نيويورك،
          الولايات المتحدة الأمريكية، مع مهمة بحثية حول «الإسلام والعقل العربي
          والفقه واللغة»، وفي معهد اللغة العربية بالجامعة الأمريكية بالقاهرة،
          وفي قسم القانون بجامعة ماتشيراتا. في عام 2007، حصل على دكتوراه في
          الأدب العربي من جامعة عين شمس، القاهرة (مصر).
        </Text>
        <Text fontSize="2xl" m="4">
          شغل عضوية اللجنة العلمية لمشروعMilano Città Mondo # 03 Egypt ، الذي
          صممه مكتب التشبيك والتعاون الثقافي ببلدية ميلانو ومتحف الثقافات
          .(MUDEC) ومقرر اللجنة العلمية لمركز أبحاث اللغة العربية (CARA) في كلية
          العلوم اللغوية والآداب الأجنبية، في الجامعة الكاثوليكية للقلب المقدس
          في ميلانو. كما أنه منسق اللجنة العلمية للمهرجان الدولي للغة والثقافة
          العربية بميلانو.
        </Text>
        <Text fontSize="2xl" m="4">
          نسَّق الكثير من مشاريع التعاون البحثي التي تناولت على وجه الخصوص،
          وجهات النظر الجديدة لتحليل الخطاب الأسلوبي، والرؤى التاريخية اللغوية
          في اللغة السياسية الدينية والأبحاث اللغوية، قام بتطوير مناهج تعليم/
          تعلم اللغة العربية للمتحدثين باللغة الإيطالية.
        </Text>
        <Text fontSize="2xl" m="4">
          نشر العديد من الكتب والأبحاث باللغة الانجليزية والعربية والإيطالية كما
          ترجم الدكتور فاروق ديوان الشاعر اللبناني وديع سعادة «بسبب غيمة على
          الأرجح» إلى الإيطالية.
        </Text>
      </Box>
    </Box>
  );
}
